import React from 'react';
import {
  Drawer, List, Grid, IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import { ReactComponent as Logo } from '../../assets/menu-new.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboardIcon.svg';
import { ReactComponent as TrainingIcon } from '../../assets/training.svg';
import { ReactComponent as InventoryIcon } from '../../assets/inventoryIcon.svg';
import { ReactComponent as PropertiesIcon } from '../../assets/propertiesIcon.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/integrationsIcon.svg';
import { ReactComponent as AccountIcon } from '../../assets/accountIcon.svg';
import { ReactComponent as CommunityIcon } from '../../assets/community.svg';
import { ReactComponent as ShowingsIcon } from '../../assets/showings.svg';
import { ReactComponent as UsersIcon } from '../../assets/users.svg';
import { ReactComponent as FAQIcon } from '../../assets/FAQIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { ReactComponent as ReportsIcon } from '../../assets/reports.svg';
import { ReactComponent as ChatIcon } from '../../assets/chatIcon.svg';
import { ReactComponent as WebhookIcon } from '../../assets/webhooks.svg';

import SmallLogoIcon from '../Icons/SmallLogoIcon';
import MenuItem from './MenuItem';
import SubMenuItem from './MenuItem/SubMenuItem';

import useStyles from './styles';

type Props = {
  userRole: string;
  isRental: boolean;
};

type SideBarMenuItem = {
  label: string;
  icon?: React.ElementType;
  path: string;
  roles?: string[];
  newFeature?: boolean;
  beta?: boolean;
  id?: string;
  children?: (isRental?: boolean) => SideBarMenuItem[];
};

// commented out, bsc we don't want to show this yet
const menuItems: SideBarMenuItem[] = [
  { label: 'Dashboard', icon: DashboardIcon, path: '/dashboard' },
  {
    label: 'Showings',
    icon: ShowingsIcon,
    path: '/customers',
    children: (isRental) => [
      { label: 'Customers', path: '/customers' },
      { label: 'Visits', path: '/visits' },
      {
        label: 'Scheduled Visits', path: '/schedule-visit', id: 'fs-new-scheduled-visits',
      },
      {
        label: isRental ? 'Ready to Rent' : 'Ready to Buy', path: '/offers',
      },
      {
        label: 'After Tour Hours', path: '/afterTourHours',
      },
    ],
  },
  {
    label: 'Hardware',
    icon: InventoryIcon,
    path: '/inventory',
    children: () => [
      { label: 'List', path: '/inventory' },
      { label: 'History', path: '/inventory-history' },
    ],
  },
  { label: 'Communities', icon: CommunityIcon, path: '/communities' },
  { label: 'Properties', icon: PropertiesIcon, path: '/properties' },
  {
    label: 'Users',
    icon: UsersIcon,
    path: '/agents',
    children: () => [
      {
        label: 'Staff', icon: AccountIcon, path: '/agents', roles: ['PM'],
      },
      {
        label: 'Teams', icon: AccountIcon, path: '/teams',
      },
    ],
  },
  {
    label: 'Settings',
    icon: SettingsIcon,
    path: '/settings',
    children: () => [
      {
        label: 'General', path: '/settings',
      },
      {
        label: 'Survey', path: '/survey',
      },
      {
        label: 'Content', path: '/content-settings',
      },
      {
        label: 'Disclosures', path: '/disclosures',
      },
      { label: 'Messaging', path: '/messaging', beta: true },
    ],
  },
  { label: 'Webhooks', icon: WebhookIcon, path: '/webhooks' },
  { label: 'Reports', icon: ReportsIcon, path: '/reports' },
  { label: 'Integrations', icon: IntegrationIcon, path: '/integrations' },
  { label: 'Training', icon: TrainingIcon, path: '/training' },

  // { label: "Profit Center", icon: ProfitCenterIcon, path: "/profit-center" },
  // { label: "Insights", icon: InsightsIcon, path: "/insights" },
  // { label: 'Workbench', icon: WorkbenchIcon, path: '/workbench' },
  // { label: "Account", icon: AccountIcon, path: "/account" }
];

const Sidebar: React.FC<Props> = ({ isRental }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleBarOpen = () => {
    setOpen(true);
  };

  const handleBarClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant="permanent"
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
      onMouseEnter={handleBarOpen}
      onMouseLeave={handleBarClose}
    >
      {window.innerWidth < 600 && (
      <IconButton
        onClick={() => (open ? handleBarClose() : handleBarOpen())}
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      )}
      <Grid className={classes.logoWrapper} container justify="center" alignItems="center">
        <Grid item>
          {open ? (
            <Logo />
          )
            : (
              <SmallLogoIcon
                // width={25}
                // height={47}
                classes={{ root: classes.smallLogo }}
              />
            )}
        </Grid>
      </Grid>
      <List className={classes.menuItems}>
        {menuItems.map(({
          id, label, path,
          icon, newFeature = false, beta = false,
          children,
        }) => {
          if (children) {
            return (
              <div key={path}>
                <SubMenuItem
                  title={label}
                  icon={icon as React.ElementType}
                  items={children(isRental)}
                />
              </div>
            );
          }
          return (
            <MenuItem
              key={path}
              label={label}
              path={path}
              icon={icon as React.ElementType}
              newFeature={newFeature}
              beta={beta}
              id={id}
            />
          );
        })}
        <a
          href="https://app.homescribe.ai/chat"
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuItem
            key="HomeScribeAI"
            label="HomeScribeAI"
            path="#"
            icon={ChatIcon}
          />
        </a>
        <a
          href="https://nternow.freshdesk.com/support/solutions/63000078717"
          style={{ textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuItem
            key="FAQ"
            label="FAQ"
            path="#"
            icon={FAQIcon}
          />
        </a>
      </List>
    </Drawer>
  );
};

export default Sidebar;
