import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import Messaging from './Messaging';
import { getBuilderName, getBuilderId } from '../../redux/selectors/auth';

import { saveBuilderMessaging } from '../../redux/actions/builders';
import { selectBuilderSettingsLoading } from '../../redux/selectors/builders';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
  builderName: getBuilderName(state),
  isLoading: selectBuilderSettingsLoading(state),
});

const mapDispatchToProps = {
  saveMessaging: saveBuilderMessaging,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messaging);
