import {
  BUILDERS_REQUEST_SUCCESS,
  BUILDERS_REQUEST_FAILED,
  BUILDERS_REQUEST,
  ENTERPRISE_BUILDERS_REQUEST,
  ENTERPRISE_BUILDERS_REQUEST_FAILED,
  ENTERPRISE_BUILDERS_REQUEST_SUCCESS,
  SAVE_BUILDER_SETTINGS,
  SAVE_BUILDER_SETTINGS_FAILED,
  SAVE_BUILDER_SETTINGS_SUCCESS,
  BuildersRequestSuccess,
  BuilderSettings,
  BUILDERS_SETTINGS_REQUEST,
  BUILDERS_SETTINGS_REQUEST_FAILED,
  BUILDERS_SETTINGS_REQUEST_SUCCESS,
  BUILDER_INTEGRATIONS_REQUEST,
  BUILDER_INTEGRATIONS_FAILED,
  BUILDER_INTEGRATIONS_SUCCESS,
  BUILDER_TIMEZONE_REQUEST,
  BUILDER_TIMEZONE_FAILED,
  BUILDER_TIMEZONE_SUCCESS,
  BUILDER_TIMEZONE_UPDATE_REQUEST,
  BUILDER_TIMEZONE_UPDATE_FAILED,
  BUILDER_TIMEZONE_UPDATE_SUCCESS,
  BuilderFeedSettings,
  BUILDER_FEED_SETTINGS_UPDATE_SUCCESS,
  BUILDER_FEED_SETTINGS_UPDATE_FAILED,
  BUILDER_FEED_SETTINGS_UPDATE,
  BUILDER_FEED_SETTINGS_REQUEST,
  BUILDER_FEED_SETTINGS_REQUEST_SUCCESS,
  BUILDER_FEED_SETTINGS_REQUEST_FAILED,
  ENTERPRISE_USER_BUILDERS_REQUEST,
  ENTERPRISE_USER_BUILDERS_REQUEST_FAILED,
  ENTERPRISE_USER_BUILDERS_REQUEST_SUCCESS,
  SAVE_BUILDER_DISCLOSURES,
  SAVE_BUILDER_DISCLOSURES_FAILED,
  SAVE_BUILDER_DISCLOSURES_SUCCESS,
  SAVE_BUILDER_MESSAGING,
  SAVE_BUILDER_MESSAGING_FAILED,
  SAVE_BUILDER_MESSAGING_SUCCESS,
} from '../types/builders';
import { Query } from '../../utils/query';
import { ErrorMessage, SuccessMessage } from '../../types/main';

export const buildersRequest = (query?: Query) => ({
  type: BUILDERS_REQUEST,
  payload: query,
} as const);

export const buildersRequestFailed = (payload: ErrorMessage) => ({
  type: BUILDERS_REQUEST_FAILED,
  payload,
} as const);

export const buildersRequestSuccess = (payload: BuildersRequestSuccess) => ({
  type: BUILDERS_REQUEST_SUCCESS,
  payload,
} as const);

export const enterpriseBuildersRequest = (enterpriseId: number) => ({
  type: ENTERPRISE_BUILDERS_REQUEST,
  payload: enterpriseId,
} as const);

export const enterpriseBuildersRequestFailed = (payload: ErrorMessage) => ({
  type: ENTERPRISE_BUILDERS_REQUEST_FAILED,
  payload,
} as const);

export const enterpriseBuildersRequestSuccess = (payload: BuildersRequestSuccess) => ({
  type: ENTERPRISE_BUILDERS_REQUEST_SUCCESS,
  payload,
} as const);

export const enterpriseUserBuildersRequest = (username: string) => ({
  type: ENTERPRISE_USER_BUILDERS_REQUEST,
  payload: username,
} as const);

export const enterpriseUserBuildersRequestFailed = (payload: ErrorMessage) => ({
  type: ENTERPRISE_USER_BUILDERS_REQUEST_FAILED,
  payload,
} as const);

export const enterpriseUserBuildersRequestSuccess = (payload: BuildersRequestSuccess) => ({
  type: ENTERPRISE_USER_BUILDERS_REQUEST_SUCCESS,
  payload,
} as const);

export const builderSettingsRequest = (id: number) => ({
  type: BUILDERS_SETTINGS_REQUEST,
  payload: id,
} as const);

export const builderSettingsRequestFailed = (payload: ErrorMessage) => ({
  type: BUILDERS_SETTINGS_REQUEST_FAILED,
  payload,
} as const);

export const builderSettingsRequestSuccess = (payload: BuilderSettings) => ({
  type: BUILDERS_SETTINGS_REQUEST_SUCCESS,
  payload,
} as const);

export const builderIntegrationsRequest = () => ({
  type: BUILDER_INTEGRATIONS_REQUEST,
} as const);

export const builderIntegrationsFailed = (payload: ErrorMessage) => ({
  type: BUILDER_INTEGRATIONS_FAILED,
  payload,
} as const);

export const builderIntegrationsSuccess = (payload: any) => ({
  type: BUILDER_INTEGRATIONS_SUCCESS,
  payload,
} as const);

export const saveBuilderSettings = (payload: FormData) => ({
  type: SAVE_BUILDER_SETTINGS,
  payload,
} as const);

export const saveBuilderSettingsFailed = (payload: ErrorMessage) => ({
  type: SAVE_BUILDER_SETTINGS_FAILED,
  payload,
} as const);

export const saveBuilderSettingsSuccess = (payload: SuccessMessage) => ({
  type: SAVE_BUILDER_SETTINGS_SUCCESS,
  payload,
} as const);

export const saveBuilderDisclosures = (payload: FormData) => ({
  type: SAVE_BUILDER_DISCLOSURES,
  payload,
} as const);

export const saveBuilderDisclosuresFailed = (payload: ErrorMessage) => ({
  type: SAVE_BUILDER_DISCLOSURES_FAILED,
  payload,
} as const);

export const saveBuilderDisclosuresSuccess = (payload: SuccessMessage) => ({
  type: SAVE_BUILDER_DISCLOSURES_SUCCESS,
  payload,
} as const);

export const saveBuilderMessaging = (payload: any) => ({
  type: SAVE_BUILDER_MESSAGING,
  payload,
} as const);

export const saveBuilderMessagingFailed = (payload: ErrorMessage) => ({
  type: SAVE_BUILDER_MESSAGING_FAILED,
  payload,
} as const);

export const saveBuilderMessagingSuccess = (payload: SuccessMessage) => ({
  type: SAVE_BUILDER_MESSAGING_SUCCESS,
  payload,
} as const);

export const builderTimezoneRequest = () => ({
  type: BUILDER_TIMEZONE_REQUEST,
} as const);

export const builderTimezoneFailed = (payload: ErrorMessage) => ({
  type: BUILDER_TIMEZONE_FAILED,
  payload,
} as const);

export const builderTimezoneSuccess = (payload: any) => ({
  type: BUILDER_TIMEZONE_SUCCESS,
  payload,
} as const);

export const builderTimezoneUpdateRequest = (timezone: number | null) => ({
  type: BUILDER_TIMEZONE_UPDATE_REQUEST,
  payload: timezone,
} as const);

export const builderTimezoneUpdateFailed = (payload: ErrorMessage) => ({
  type: BUILDER_TIMEZONE_UPDATE_FAILED,
  payload,
} as const);

export const builderTimezoneUpdateSuccess = (payload: number | null) => ({
  type: BUILDER_TIMEZONE_UPDATE_SUCCESS,
  payload,
} as const);

export const builderFeedSettingsRequest = (builderId: number) => ({
  type: BUILDER_FEED_SETTINGS_REQUEST,
  payload: builderId,
} as const);

export const builderFeedSettingsRequestSuccess = (payload: BuilderFeedSettings) => ({
  type: BUILDER_FEED_SETTINGS_REQUEST_SUCCESS,
  payload,
} as const);

export const builderFeedSettingsRequestFailed = (payload: ErrorMessage) => ({
  type: BUILDER_FEED_SETTINGS_REQUEST_FAILED,
  payload,
} as const);

export const updateBuilderFeedSettingsRequest = (payload: BuilderFeedSettings) => ({
  type: BUILDER_FEED_SETTINGS_UPDATE,
  payload,
} as const);

export const updateBuilderFeedSettingsSuccess = (payload: SuccessMessage) => ({
  type: BUILDER_FEED_SETTINGS_UPDATE_SUCCESS,
  payload,
} as const);

export const updateBuilderFeedSettingsFailed = (payload: ErrorMessage) => ({
  type: BUILDER_FEED_SETTINGS_UPDATE_FAILED,
  payload,
} as const);
