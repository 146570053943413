/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
/* eslint-disable eol-last */
import * as yup from 'yup';

export const generateLabel = (text: string) => text
  .split(/(?=[A-Z][^A-Z_])|_/g)
  .map(word => word.charAt(0).toUpperCase().concat(word.slice(1)))
  .join(' ');

export const isCheckable = (name: string) => ['send_email', 'send_sms', 'isActive', 'lock_battery_level_send_email', 'lock_battery_level_send_sms', 'lock_offline_send_email', 'lock_offline_send_sms'].includes(name);
type DefaultValidationErrors = { [key: string]: string };

export type ValidationErrors<T> = {
  [key in keyof T]?: string;
};

export function beautifyErrors<T = DefaultValidationErrors>(yupErrors: any): ValidationErrors<T> {
  return Object
    .fromEntries(
      yupErrors.inner.map((e: { path: string, message: string }) => [e.path, e.message]),
    );
}

export function getInitials(firstname: string, lastname: string): string {
  return `${firstname[0]}${lastname[0]}`;
}

const checkIdenticalCodes = (codes: string[]) => {
  const values = Object.values(codes);
  const uniqueValues = values.filter(
    (code, index, array) => code.length === 0 || array.indexOf(code) === index,
  );

  if (values.length !== uniqueValues.length) {
    const pathes = Object.entries(codes)
      .filter(([path, code], index, array) => array.some(([p, c]) => c === code && p !== path))
      .map(([path]) => path);

    const errors = pathes.map(
      (path) => new yup.ValidationError('Identical codes are not allowed! Empty slots are acceptable', '', path),
    );

    return new (yup.ValidationError as any)(errors);
  }

  return true;
};

export const checkOnlyDigits = (value: string) => /^(\s*|\d+)$/.test(value ?? '');

export const identicalCodesSchema = yup
  .object()
  .test(
    'Identical Codes',
    'Identical codes are not allowed! Empty slots are acceptable',
    checkIdenticalCodes,
  );

export const convertKeyToLabel = (key: string) => {
  const words = key.split('_');
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const replacePlaceholders = (
  template: string,
  variables: { [key: string]: string },
) => {
  if (!template) return null;

  return Object.keys(variables).reduce((result, key) => {
    const placeholder = `{${key}}`;
    return result
      .split(placeholder)
      .join(variables[key] !== undefined ? variables[key] : placeholder);
  }, template);
};
